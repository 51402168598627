<template>
  <nav
    id="top"
    class="fixed nav-wrapper w-full"
    x-data="{ openmobile: false }"
    x-on:click.away="openmobile = false"
    :class="[{'mobile-open' : mobile_open, 'desktop-menu-open': desktop_menu_open, 'user-is-authenticated': user.authenticated}, 'ccss--' + user.username]"
  >
    <div
      v-if="location.id"
      id="topbar"
      class="hidden md:block items-center border-b"
    >
      <div
        v-if="config.theme.show_opening_hours == true"
        class="py-2 mx-auto px-4 sm:px-6 lg:px-8 flex justify-end text-xs items-center mr-3"
        :class="{'opening-hours': config.theme.show_opening_hours == true }"
      >
        <div
          class="relative inline-block text-left mr-3"
          x-data="{open: false}"
          x-on:click.away="open = false"
        >
          <div>
            <div
              id="options-menu"
              x-on:click="open = !open"
              class="cursor-pointer flex justify-center"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ $t('navigation.openingtimes') }}
              <svg
                class="-mr-1 ml-1 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div
            x-show="open"
            class="navigation-dropdown dropdown origin-top-left absolute right-0 mt-2 w-56 rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 radius-anchor"
          >
            <div class="px-4 py-3">
              <p class="text-sm font-bold  truncate">
                {{ location.name }}
              </p>
            </div>
            <div
              class="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div
                v-for="(times, day) in location.openingstimes"
                :key="day"
                class="flex px-4 pb-1 text-sm"
                :class="{'font-bold': today == day}"
              >
                <div class="w-1/2">
                  {{ $t('openingtimes.' + day) }}:
                </div>
                <div
                  v-if="times"
                  class="w-1/2"
                >
                  {{ times }}
                </div>
                <div
                  v-else
                  class="w-1/2"
                >
                  Gesloten
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-start items-center mr-3">
          <svg
            class="mr-2"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8V12L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div v-if="location.today.open">
            {{ location.today?.openingstimes }}
          </div>
          <div v-else>
            Vandaag gesloten
          </div>
        </div>
        <div
          v-if="location.streetname || location.zipcode || location.city"
          class="flex justify-start items-center mr-3"
        >
          <svg
            class="mr-2"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.6569 16.6569C16.7202 17.5935 14.7616 19.5521 13.4138 20.8999C12.6327 21.681 11.3677 21.6814 10.5866 20.9003C9.26234 19.576 7.34159 17.6553 6.34315 16.6569C3.21895 13.5327 3.21895 8.46734 6.34315 5.34315C9.46734 2.21895 14.5327 2.21895 17.6569 5.34315C20.781 8.46734 20.781 13.5327 17.6569 16.6569Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div>
            {{ location.streetname }} {{ location.housenumber }}, {{ location.zipcode }}, {{ location.city }}
          </div>
        </div>
        <div
          v-if="location.phone_number"
          class="flex justify-start items-center mr-3"
        >
          <svg
            class="mr-2"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 5C3 3.89543 3.89543 3 5 3H8.27924C8.70967 3 9.09181 3.27543 9.22792 3.68377L10.7257 8.17721C10.8831 8.64932 10.6694 9.16531 10.2243 9.38787L7.96701 10.5165C9.06925 12.9612 11.0388 14.9308 13.4835 16.033L14.6121 13.7757C14.8347 13.3306 15.3507 13.1169 15.8228 13.2743L20.3162 14.7721C20.7246 14.9082 21 15.2903 21 15.7208V19C21 20.1046 20.1046 21 19 21H18C9.71573 21 3 14.2843 3 6V5Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div>{{ location.phone_number }}</div>
        </div>
      </div>
    </div>

    <div
      v-if="location"
      id="navigation"
    >
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative flex justify-between items-center h-16">
        <span class="hide-desktop nav-location font-bold">
          {{ location.name }}
        </span>

        <div
          class="text-center text-xs nav-location nav-eventSelected leading-4"
          style="font-size: 12px"
        >
          <div class="font-bold">
            {{ elockers_event?.name }}
          </div>
          <div class="opacity-70">
            {{ elockers_event?.date }}
          </div>
        </div>

        <div class="flex">
          <!-- {{applicationStartPage}} -->
          <router-link
            :to="{name: applicationStartPage }"
            class="flex-shrink-0 flex items-center logo-link"
            @click="mobile_open = false"
          >
            <img
              v-if="logo"
              :class="'logo ' + logo_class"
              :src="logo"
            >
            <h2
              v-else
              class="text-xl mobile-nav-text"
            >
              {{ config.settings.client }}
            </h2>
          </router-link>
          <div
            v-if="navigation_active == true"
            class="menu-links hidden md:ml-6 md:flex md:items-center md:space-x-4"
          >
            <router-link
              v-if="applicationFunctions.delivery || applicationFunctions.takeaway"
              :to="{ name: 'deliverymethod', params: {location_slug: location.slug}}"
              class="nav-menu-link radius-anchor"
            >
              {{ $t('navigation.deliverymethod') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.quickorder"
              :to="{ name: 'quickorder', params: {location_slug: location.slug}}"
              class="nav-menu-link radius-anchor"
            >
              {{ $t('navigation.quickorder') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.menu"
              :to="{ name: 'menu', params: {location_slug: location.slug}}"
              class="nav-menu-link radius-anchor"
            >
              {{ $t('navigation.menu') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.elockers"
              :to="{ name: 'lockers', params: {location_slug: location.slug}}"
              class="nav-menu-link radius-anchor"
            >
              {{ $t('navigation.elockers') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.reservation"
              :to="{ name: 'reservation', params: {location_slug: location.slug}}"
              class="nav-menu-link radius-anchor"
            >
              {{ $t('navigation.reservation') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.visitors"
              :to="{ name: 'visitors', params: {location_slug: location.slug}}"
              class="nav-menu-link radius-anchor"
            >
              {{ $t('navigation.visitors') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.wallet"
              :to="{ name: 'checkout.wallet', params: {location_slug: location.slug}}"
              class="nav-menu-link radius-anchor"
            >
              {{ $t('navigation.walletorder') }}
            </router-link>
          </div>
        </div>

        <div class="flex items-center">
          <a
            v-if="custom_link && custom_link.url"
            :href="custom_link.url"
            :target="custom_link.newtab ? '_blank' : ''"
            class="nav-menu-link hidden md:flex cursor-pointer"
          >{{ custom_link.label }}</a>


          <router-link
            v-if="config.settings.wallettoken_company.active == true"
            :to="{ name: 'wallettoken.activate'}"
            class="nav-menu-link hidden md:flex cursor-pointer"
          >
            {{ $t('navigation.wallettoken.activate') }}
          </router-link>
          <!-- <a @click="$refs.activatewallettoken.open()" class="cursor-pointer hidden md:flex hover:underline px-3 py-2 rounded-sm text-sm font-medium">{{ $t('wallettoken.activate.open') }}</a> -->
          <!--                    <router-link v-if="applicationFunctions.wallet" :to="{ name: 'checkout.wallet', params: {location_slug: location.slug}}" class="px-3 py-2 btn text-sm font-medium" :class="current_route == 'checkout.wallet' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-white hover:bg-gray-700">{{ $t('navigation.walletorder') }}</router-link>-->
          <div
            v-if="!user.authenticated"
            class="nav-menu-link cursor-pointer hidden md:flex px-3 py-2 rounded-sm font-semibold"
            @click="$refs.login.open()"
          >
            {{ $t('auth.login') }}
          </div>


          <div
            v-else
            class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center"
          >
            <!-- Profile dropdown -->
            <div
              class="ml-3 relative"
              x-data="{ open: false }"
              x-on:click.away="open = false"
            >
              <div>
                <a
                  class="cursor-pointer nav-menu-link"
                  @click="desktop_menu_open = !desktop_menu_open"
                >
                  {{ user.username }}
                </a>
              </div>
              <div
                class="desktop-menu-foldout dropdown origin-top-right"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <div
                  class="close"
                  @click="desktop_menu_open = !desktop_menu_open"
                >
                  <div />
                  <div />
                </div>

                <a
                  v-if="user.hasAccounts"
                  x-on:click="open = false"
                  class="items-center flex justify-between cursor-pointer block px-4 py-2 text-sm"
                  role="menuitem"
                  @click="$refs.accounts.open(); desktop_menu_open = !desktop_menu_open"
                >
                  <div v-if="!transaction.account">{{ $t('navigation.usermenu.selectaccount') }}</div>
                  <div v-else>{{ transaction.account.name }}</div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                      />
                    </svg>
                  </div>
                </a>
                <router-link
                  :to="{ name: 'user.myaccount' }"
                  class="block px-4 py-2 text-sm"
                  @click="desktop_menu_open = !desktop_menu_open"
                >
                  {{ $t('navigation.usermenu.account') }}
                </router-link>
                <a
                  x-on:click="open = false"
                  class="cursor-pointer block px-4 py-2 text-sm"
                  role="menuitem"
                  @click="logout(); desktop_menu_open = !desktop_menu_open"
                >{{ $t('auth.logout') }}</a>
              </div>
              <transition
                name="blur-background"
                @click="desktop_menu_open = false"
              >
                <blur v-if="desktop_menu_open" />
              </transition>
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div class="mr-2 flex items-center md:hidden">
              <!-- Mobile menu button -->
              <button
                class="hamburger inline-flex items-center justify-center p-2 pl-0 rounded-sm focus:outline-none"
                aria-expanded="false"
                @click="mobile_open = !mobile_open"
              >
                <!--                                <span class="sr-only">Open main menu</span>-->
                <!--                                <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
                <!--                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />-->
                <!--                                </svg>-->
                <!--                                <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
                <!--                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />-->
                <!--                                </svg>-->

                <div
                  class="hamburger-menu"
                  :class="{'menu-open' : mobile_open }"
                >
                  <div />
                  <div />
                  <div />
                </div>
              </button>
            </div>

            <div
              class="language-selection"
              :class="'currentLang-' + activeLanguage"
              @click.stop="languageSelectorOpen = !languageSelectorOpen"
            >
              <div class="flag-toggle">
                <img
                  style="width:25px;"
                  :src="'/img/flags/' + activeLanguage + '.png'"
                >
              </div>

              <div
                v-if="languageSelectorOpen == true"
                class="language-selection-menu"
                @click.stop
              >
                <div
                  v-for="(lan, index) in languages.filter(language => language !== activeLanguage)"
                  :key="index"
                  class="cursor-pointer"
                  @click.stop="setLanguage(lan)"
                >
                  <a class="flag-toggle">
                    <img
                      style="width:25px;"
                      :src="'/img/flags/' + lan + '.png'"
                    >
                  </a>
                  <div class="uppercase">
                    {{ lan }}
                  </div>
                </div>
              </div>
            </div>
            <!--                        // if cart is fixed-->
            <div
              v-if="config.theme.cart_style === 'cart-fixed'"
              class="fixed-cart-toggle radius-anchor"
              @click="cartToggle()"
            >
              <span
                v-if="transaction.lines.length"
                class="cart-counter"
              >
                {{ transaction.lines.length }}
              </span>

              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                stroke="currentColor"
              >
                <g
                  data-name="Layer 2"
                  fill="currentColor"
                >
                  <g data-name="shopping-cart"><rect
                                                 width="24"
                                                 height="24"
                                                 opacity="0"
                                               />
                    <path
                      fill="currentColor"
                      stroke="currentColor"
                      d="M21.08 7a2 2 0 0 0-1.7-1H6.58L6 3.74A1 1 0 0 0 5 3H3a1 1 0 0 0 0 2h1.24L7 15.26A1 1 0 0 0 8 16h9a1 1 0 0 0 .89-.55l3.28-6.56A2 2 0 0 0 21.08 7z"
                    />
                    <circle
                      fill="currentColor"
                      stroke="currentColor"
                      cx="7.5"
                      cy="19.5"
                      r="1.5"
                    /><circle
                      fill="currentColor"
                      stroke="currentColor"
                      cx="17.5"
                      cy="19.5"
                      r="1.5"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide-left">
      <div
        v-if="mobile_open"
        id="navigation-mobile"
      >
        <div class="nav-inner-mobile">
          <div class="mobile-title">
            <h1>Menu</h1>
          </div>
          <div
            v-if="location.id && (applicationFunctions.delivery || applicationFunctions.takeaway || applicationFunctions.quickorder || applicationFunctions.menu || applicationFunctions.elockers || applicationFunctions.reservation || applicationFunctions.visitors)"
            class="mobile-menu-box radius-anchor"
          >
            <router-link
              v-if="applicationFunctions.delivery || applicationFunctions.takeaway"
              :to="{ name: 'deliverymethod', params: {location_slug: location.slug}}"
              class="block text-lg font-medium"
              @click="mobile_open = !mobile_open"
            >
              {{ $t('navigation.deliverymethod') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.quickorder"
              :to="{ name: 'quickorder', params: {location_slug: location.slug}}"
              class="block text-lg font-medium"
              @click="mobile_open = !mobile_open"
            >
              {{ $t('navigation.quickorder') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.menu"
              :to="{ name: 'menu', params: {location_slug: location.slug}}"
              class="block text-lg font-medium"
              @click="mobile_open = !mobile_open"
            >
              {{ $t('navigation.menu') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.elockers"
              :to="{ name: 'lockers', params: {location_slug: location.slug}}"
              class="block text-lg font-medium"
              @click="mobile_open = !mobile_open"
            >
              {{ $t('navigation.elockers') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.reservation"
              :to="{ name: 'reservation', params: {location_slug: location.slug}}"
              class="block text-lg font-medium"
              @click="mobile_open = !mobile_open"
            >
              {{ $t('navigation.reservation') }}
            </router-link>
            <router-link
              v-if="applicationFunctions.visitors"
              :to="{ name: 'visitors', params: {location_slug: location.slug}}"
              class="block text-lg font-medium"
              @click="mobile_open = !mobile_open"
            >
              {{ $t('navigation.visitors') }}
            </router-link>
          </div>
          <div
            class="mobile-menu-box radius-anchor"
            :class="{ 'border-t border-gray-700' : user.authenticated }"
          >
            <a
              v-if="custom_link && custom_link.url"
              :href="custom_link.url"
              :target="custom_link.newtab ? '_blank' : ''"
              class="cursor-pointer flex justify-start rounded-sm text-lg font-medium"
              @click="mobile_open = !mobile_open"
            >
              {{ custom_link.label }}
            </a>
            <router-link
              v-if="config.settings.wallettoken_company.active == true"
              :to="{ name: 'wallettoken.activate'}"
              class="cursor-pointer flex justify-start rounded-sm text-lg font-medium"
              @click="mobile_open = !mobile_open"
            >
              {{ $t('navigation.wallettoken.activate') }}
            </router-link>

            <a
              v-if="!user.authenticated"
              class="cursor-pointer flex justify-start rounded-sm text-lg font-medium"
              @click="$refs.login.open()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-3 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                />
              </svg>
              {{ $t('auth.login') }}
            </a>

            <div
              v-else
              class="block"
            >
              <div class="relative">
                <div>
                  <a class="cursor-pointer text-lg font-medium block">
                    {{ user.username }}
                  </a>
                </div>
                <div class="profile-actions">
                  <span
                    v-if="user.hasAccounts"
                    class="flex justify-start items-center pr-4 menu-link"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                        />
                      </svg>
                    </div>
                    <a
                      v-if="!transaction.account"
                      class="cursor-pointer block text-lg font-medium"
                      role="menuitem"
                      @click="$refs.accounts.open()"
                    >{{ $t('navigation.usermenu.selectaccount') }}</a>
                    <a
                      v-else
                      class="cursor-pointer block text-lg font-medium"
                      role="menuitem"
                    >Account: {{ transaction.account.name }}</a>
                  </span>
                  <router-link
                    :to="{ name: 'user.myaccount' }"
                    class="block text-lg font-medium menu-link"
                    @click="mobile_open = !mobile_open"
                  >
                    {{ $t('myaccount') }}
                  </router-link>
                  <span
                    class="flex justify-start items-center pr-4 menu-link"
                    @click="mobile_open = !mobile_open"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    <a
                      x-on:click="open = false"
                      class="cursor-pointer flex justify-start text-lg font-medium"
                      role="menuitem"
                      @click="logout()"
                    >
                      {{ $t('auth.logout') }}
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <router-link
              v-if="location && location.slug && applicationFunctions.wallet"
              :to="{ name: 'checkout.wallet', params: {location_slug: location.slug}}"
              class="cursor-pointer flex justify-start rounded-sm text-lg font-medium"
              :class="current_route == 'checkout.wallet' ? 'bg-gray-900 text-white' : 'text-gray-300'"
              @click="mobile_open = !mobile_open"
            >
              {{ $t('navigation.walletorder') }}
            </router-link>
          </div>
        </div>
      </div>
    </transition>

    <!-- modals -->
    <modal-accounts
      ref="accounts"
      @closeMobileMenu="closeMenu()"
    />
    <modal-login
      ref="login"
      @open-accounts="$refs.accounts.open()"
      @open-register="$refs.register.open()"
      @open-passwordforget="$refs.passwordforget.open()"
    />
    <modal-register
      ref="register"
      @open-login="$refs.login.open()"
    />
    <modal-password-forget
      ref="passwordforget"
      @open-login="$refs.login.open()"
    />
    <!--         <modal-wallettoken-activate ref="activatewallettoken" />-->
  </nav>
</template>

<script>
import 'alpinejs'
import moment from 'moment'

// libs
import * as _auth from "../lib/auth";
import * as _state from "../lib/state";
import * as _location from "../lib/location";
import * as _applicationState from "../lib/application-state";
import * as _transaction from '../lib/transaction';
// components
import ModalAccounts from '../components/ModalAccounts'
import ModalLogin from '../components/ModalLogin'
import ModalPasswordForget from '../components/ModalPasswordForget'
import ModalRegister from '../components/ModalRegister'
import Blur from "./Blur";

export default {
    name: 'Navigation',
    // emits: ['closeMobileMenu'],
    components: {
        ModalAccounts,
        ModalLogin,
        ModalPasswordForget,
        ModalRegister,
        Blur,
        // ModalWallettokenActivate,
    },
    data() {
        return {
            usermenu: false,
            isHooli: false,
            logo: null,
            elockers_event: null,
            logo_class: 'no-logo',
            mobile_open: false,
            desktop_menu_open: false,
            languageSelectorOpen: false,
            activeLanguage: 'nl',
            languages: ['en', 'fr', 'nl', 'de', 'es', 'it']
        }
    },

    computed: {
        current_route() {
            return this.$route.name
        },

        location_slug() {
            return this.$route.params.location_slug
        },

        // lockerFlowEventSelected() {
        //   const state = document.body.classList.contains('eventSelected')

        //   return state
        // },

        navigation_active() {
            if (this.$route.path == '/') {
                return false;
            }

            if (this.$route.path == '/locations') {
                return false;
            }

            return this.config.settings.navigation;
        },

        custom_link() {
            return this.config.settings.custom_link;
        },

        today() {
            return moment().format('dddd').toLowerCase()
        },

        language() {
            return this.config.localization;
        },

        config () {
            return _state.get('config/getConfig');
        },

        location () {
            return _location.get();
        },

        user () {
            return _state.get('user/getUser');
        },

        transaction () {
            return _state.get('transaction/getTransaction');
        },

        applicationFunctions () {
            return _applicationState.getFunctions();
        },

        applicationStartPage () {
            return _applicationState.getStartPage();
        },

        getAccount () {
            return _transaction.getAccount();
        }
    },

    watch: {
        mobile_open: function () {
            document.body.classList.toggle('mobile-menu-open');
        },
    },

    mounted() {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            this.activeLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
            _state.setField('config/getConfig', 'config/setConfig', 'localization', savedLanguage);
            _state.set('config/getConfig', {
                ..._state.get('config/getConfig'),
                localization: savedLanguage
            });
        } else {
            this.activeLanguage = this.config.localization;
        }

        this.isHooli = _applicationState.getIsHooli()

        console.log('isHooli', this.isHooli);

        if (this.isHooli) {
          this.logo = false;
          this.logo_class = 'logo-hooli';
        } else if (this.location.theme_url) {
          this.logo = this.location.theme_logo;
          this.logo_class = 'logo-location';
        } else {
          this.logo = this.config.settings.logo;
          this.logo_class = 'logo-main-theme';
        }

        this.elockers_event = this.transaction.elockers_event;
    },

    methods: {
        closeMenu() {
          this.mobile_open = false;
        },

        setLanguage(language) {
            localStorage.setItem('selectedLanguage', language);

            this.activeLanguage = language;
            this.$i18n.locale = language;

            _state.setField('config/getConfig', 'config/setConfig', 'localization', language);

            _state.set('config/getConfig', {
                ..._state.get('config/getConfig'),
                localization: language
            });

            this.languageSelectorOpen = false;
        },

        cartToggle() {
            let cartElement = document.querySelector('#app .cart-fixed');

            if (cartElement) {
                cartElement.classList.toggle('cart-open');
            }        },

        logout() {
            _auth.logout();
            this.$router.push({name: 'landing'});
        },
    },
}
</script>
