import moment from 'moment';
import md5 from 'js-md5';

// libs
import * as _api from './api';
import * as _elockers from '../lib/elockers';
import * as _log from '../lib/log';
import * as _state from '../lib/state';
import * as _location from '../lib/location';
import * as _applicationState from '../lib/application-state';

// import * as _i18n from './i18n';

// set the logger
const logger = _log.get('TRANSACTION');

const init = () => {
    let transaction = {
        id: '',

        loading: false,
        method: '',
        mode: 'qrcode',
        method_delivery: false,
        method_date: false,
        method_time: false,
        lines: [],

        coupons: [],
        payments: [],
        tickets: [],
        vouchers: [],

        delivery_type: false,
        delivery_address: false,
        menu_date: moment().format('YYYY-MM-DD'),
        account: false,
        area: false,
        discount: false,
        customer: false,
        table: false,

        status: '',

        elockers_event: false,
        elockers_location: false,
        elockers_area: false,

        number_of_items: 0,
        total_without_discount: 0,
        total: 0,
        total_payable: 0,
        opt_in_marketing: false,
    };
    _state.set('transaction/setTransaction', transaction);

    logger.log('start transaction')();
};

const cancelStoredTransaction = async () => {
    let transaction = _state.get('transaction/getTransaction');

    // post the transaction to api
    let response = await _api.post('transaction/cancel', {
        transaction_id: transaction.id,
    });

    if (response && response.result === 'success') {
        logger.log('transaction canceled')();

        transaction.transaction_id = '';
        transaction.status = '';

        // set the transaction
        _state.set('transaction/setTransaction', transaction);

        return true;
    } else {
        setId(null);

        logger.error('transaction not canceled')();

        return false;
    }
};

// getters

const getMethod = () => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    return transaction.method;
};

const getHooliMember = () => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    return transaction.hooliMember;
};

const getAccount = () => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    return { id: transaction.account.id, name: transaction.account.name };
};

const getMethodDelivery = () => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    return transaction.method_delivery;
};

const getTip = () => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    let linesTipIndex = getLineById('TIP');

    if (linesTipIndex < 0) {
        return false;
    }

    let line = transaction.lines[linesTipIndex];
    if (line.tip_type == 'percentage') {
        return {
            type: line.tip_type,
            percentage: line.tip_percentage,
            value: line.rate,
        };
    }

    return {
        type: line.tip_type,
        value: line.rate,
    };
};

const getTotalWithoutFees = (includeDefaultItems = true) => {
    let total = 0;
    let lines = _state.get('transaction/getTransaction').lines;

    lines.forEach(function (line) {
        if (line.id == 'TIP' || line.id == 'TRX_FEE' || line.id == 'delivery_fee' || (includeDefaultItems && line.default_item)) {
            return;
        }
        total += line.total_without_discount;
    });
    return total;
};

const getTicketCounter = (type) => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    return transaction.lines
        .map((line) => {
            return line.tickets.filter((ticket) => ticket.vendor == type);
        })
        .flat().length;
};

const getSufficientTotalForDelivery = () => {
    let transaction_method = _state.get('transaction/getTransaction').method;

    if (transaction_method != 'delivery') {
        return true;
    }

    let delivery_fee_data = _location.get().delivery_fee;

    if (!delivery_fee_data) {
        return true;
    }

    if (getTotalWithoutFees() < parseFloat(delivery_fee_data.minimum)) {
        return delivery_fee_data.minimum;
    }

    return true;
};

const getLineAmountById = (line_id) => {
    let ln = getLineById(line_id);

    if (ln > 0) {
        let transaction = _state.get('transaction/getTransaction');
        let total = transaction.lines[ln].total;

        if (total == 0) {
            return true;
        }

        return total;
    }

    return false;
};

// setters
const setAccount = (account) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'account', account);
};
const setCustomer = (customer) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'customer', customer);
};

const setDiscount = (discount) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'discount', discount);

    applyDiscount();
};

const setDeliveryAddress = (delivery_address) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'delivery_address', delivery_address);
};

const setMenuDate = (menu_date) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'menu_date', menu_date);
};

const setArea = (area) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'area', area);
};

const setMethod = (method, mode = null) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'method', method);
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'mode', mode);
};

const setHooliMember = (member) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'hooliMember', member);
};

const setReservationId = (reservation_id) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'reservation_id', reservation_id);
};

const setMethodDelivery = (method_delivery) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'method_delivery', method_delivery);
};

const setMethodDateTime = (date, time) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'method_date', date);
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'method_time', time);
};

const setTable = (table) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'table', table);
};

const setTip = (data) => {
    let linesTipIndex = getLineById('TIP');
    let amount = parseFloat(data.amount);

    if (data.type == 'percentage') {
        amount = getTotalWithoutFees() * (data.amount / 100);
    }
    let line = {
        id: 'TIP',
        fixed_item: true,
        tip_type: data.type, // percentage
        line_is_fee: true,
        tip_percentage: data.amount, // percentage
        type: 'cashinout',
        cashfunction_id: data.cashfunction_id,
        description: 'Fooi',
        quantity: 1,
        kitchen_groceries: [],
        rate: parseFloat(amount),
        rate_without_discount: parseFloat(amount),
        total: parseFloat(amount),
    };

    if (linesTipIndex < 0) {
        addItem(line, false);
        return;
    }

    let transaction = _state.get('transaction/getTransaction');
    let ln = transaction.lines[linesTipIndex];

    if (amount <= 0) {
        subtractQuantity(ln);
        return;
    }

    ln.rate = ln.rate_without_discount = ln.total = amount;
    ln.tip_percentage = data.amount;

    setTotal(transaction);

    // save transaction
    _state.set('transaction/setTransaction', transaction);
};

const updateTip = () => {
    let transaction = _state.get('transaction/getTransaction');
    let linesTipIndex = getLineById('TIP');

    if (linesTipIndex < 0) {
        return;
    }

    let ln = transaction.lines[linesTipIndex];
    subtractQuantity(ln, false, false);

    if (ln.tip_type === 'percentage') {
        ln.rate = parseFloat(getTotalWithoutFees() * (ln.tip_percentage / 100));
    }
    ln.fixed_item = true;
    addItem(ln, false);
};

const getLineById = (id) => {
    let transaction = _state.get('transaction/getTransaction');

    return transaction.lines.findIndex((ln) => {
        return ln.id === id;
    });
};

const setTransactionFee = (payment_type) => {
    let transaction_fee = payment_type.attributes?.fee || payment_type.fee;

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // set the value
    let amount = transaction_fee.amount;
    let percentage = null;

    let linesTransactionIndex = transaction.lines.findIndex((ln) => {
        return ln.id === 'TRX_FEE';
    });
    if (linesTransactionIndex < 0) {
        if (transaction_fee.type == 'percentage') {
            amount = getTotalWithoutFees() * (parseInt(transaction_fee.percentage) / 100);

            if (amount > parseInt(transaction_fee.percentage_maximum_amount)) {
                amount = transaction_fee.percentage_maximum_amount;
            }

            percentage = transaction_fee.percentage;
        }

        if (amount <= 0) {
            return;
        }

        let line = {
            id: 'TRX_FEE',
            description: `Transactioncosts ${percentage}%`,
            transaction_fee_type: transaction_fee.type, // percentage
            transaction_fee_percentage: percentage,
            transaction_fee_percentage_maximum_amount: transaction_fee.percentage_maximum_amount,
            type: 'revenue',
            item_id: transaction_fee.item_id,
            addon: false,
            editable: false,
            quantity: 1,
            rate: parseFloat(amount),
            rate_without_discount: parseFloat(amount),
            total: parseFloat(amount),
            variant_id: null,
            variant_label: null,
        };

        addItem(line, false);
    } else {
        let ln = transaction.lines[linesTransactionIndex];

        if (transaction_fee.type == 'percentage') {
            amount = getTotalWithoutFees() * (parseInt(transaction_fee.percentage) / 100);

            if (amount > parseInt(transaction_fee.percentage_maximum_amount)) {
                amount = transaction_fee.percentage_maximum_amount;
            }

            percentage = transaction_fee.percentage;
            ln.description = `Transactioncosts ${transaction_fee.percentage}%`;
        }

        if (amount <= 0) {
            subtractQuantity(ln);
            return;
        }

        ln.transaction_fee_type = transaction_fee.type;
        ln.transaction_fee_percentage = percentage;
        ln.transaction_fee_percentage_maximum_amount = transaction_fee.percentage_maximum_amount;
        ln.item_id = transaction_fee.item_id;
        ln.rate = ln.rate_without_discount = ln.total = ln.total_without_discount = amount;

        setTotal(transaction);

        // save transaction
        _state.set('transaction/setTransaction', transaction);
    }
};

const getTransactionFee = () => {
    let transaction = _state.get('transaction/getTransaction');

    let linesTransactionIndex = transaction.lines.findIndex((ln) => {
        return ln.id === 'TRX_FEE';
    });

    if (linesTransactionIndex < 0) {
        return 0;
    }

    const ln = transaction.lines[linesTransactionIndex];

    return ln.total;
};

const updateTransactionFee = () => {
    let transaction = _state.get('transaction/getTransaction');

    let linesTransactionIndex = transaction.lines.findIndex((ln) => {
        return ln.id === 'TRX_FEE';
    });

    if (linesTransactionIndex < 0) {
        return;
    }

    let ln = transaction.lines[linesTransactionIndex];
    subtractQuantity(ln, false, false);

    if (ln.transaction_fee_type == 'percentage') {
        ln.total = getTotalWithoutFees() * (parseInt(ln.transaction_fee_percentage) / 100);
    }

    if (ln.total > parseInt(ln.percentage_maximum_amount)) {
        ln.total = ln.percentage_maximum_amount;
    }

    if (ln.total <= 0) {
        return;
    }

    ln.rate = ln.rate_without_discount = ln.total;

    addItem(ln, false);
};

const updateFees = () => {
    applyDiscount();
    updateTip();
    updateDefaultItemsFee();
    updateTransactionFee();
    setDeliveryfee();
};

const clearDiscount = () => {
    let transaction = _state.get('transaction/getTransaction');

    transaction.lines.map((line) => {
        line.rate = line.rate_without_discount;
        line.total = round(line.quantity * line.rate, 2);
    });
    transaction = setTotal(transaction);

    _state.set('transaction/setTransaction', transaction);
};
const applyDiscount = () => {
    let transaction = _state.get('transaction/getTransaction');

    const discount = transaction.discount;

    // to clear already given discount
    if (discount === false) {
        return;
    }
    if (['discount_percentage_fixed'].includes(discount.type) === false) {
        return;
    }

    transaction.lines.map((line) => {
        line.rate = round(line.rate_without_discount * ((100 - discount.value) / 100), 2);
        // line.total_without_discount = round(line.quantity * line.rate_without_discount, 2);
        line.total = round(line.quantity * line.rate, 2);
    });

    transaction = setTotal(transaction);
    _state.set('transaction/setTransaction', transaction);
};

const removeTransactionFee = () => {
    let transaction = _state.get('transaction/getTransaction');

    let linesTransactionIndex = transaction.lines.findIndex((ln) => {
        return ln.id === 'TRX_FEE';
    });

    if (linesTransactionIndex < 0) {
        return;
    }

    let ln = transaction.lines[linesTransactionIndex];

    subtractQuantity(ln);
};

const setVouchers = (vouchers) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'vouchers', vouchers);
};

const releaseTickets = () => {
    let transaction = _state.get('transaction/getTransaction');

    if (!transaction) {
        return;
    }

    let tickets = [];
    transaction.lines.forEach((line) => {
        if (line.elockers_active === true) {
            line.tickets.forEach((ticket) => {
                tickets.push(ticket);
            });
        }
    });

    if (tickets.length === 0) {
        return;
    }

    _elockers.release(tickets);
};

// refactor account reset?
const clear = (hardreset = true) => {
    let transaction = _state.get('transaction/getTransaction');

    if (hardreset === true) {
        releaseTickets();
    }

    // clear locker times
    cancelExpireLockerTimer(true);

    init();

    if (hardreset === false) {
        setAccount({
            id: transaction.account.id,
            name: transaction.account.name,
        });
    }

    logger.log('transaction cleared')();
};

const setStatus = (status) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'status', status);

    logger.log('transaction status set', status)();
};

const setId = (id) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'id', id);

    logger.log('transaction id set', id)();
};

// items
const addItem = async (data, update = true) => {
    let transaction = _state.get('transaction/getTransaction');

    // get the lines
    let lines = transaction.lines;

    // line exists?
    let line = lines.find((line) => line.id === data.id && line.parent_id === data.parent_id);

    // create new line
    if (!line) {
        let len = lines.push({
            id: data.id ?? false,
            tip_type: data.tip_type,
            tip_percentage: data.tip_percentage,
            transaction_fee_type: data.transaction_fee_type, // percentage
            transaction_fee_percentage: data.transaction_fee_percentage,
            transaction_fee_percentage_maximum_amount: data.transaction_fee_percentage_maximum_amount,
            default_item: data.default_item ?? false,
            parent_id: data.parent_id,
            cashfunction_id: data.cashfunction_id || null,
            item_id: data.item_id,
            item_parent_id: data.item_parent_id,
            type: data.type,
            line_is_fee: data.line_is_fee ?? false,
            addon: data.addon ?? false,
            editable: data.fixed_item || data.composed_child || data.addon ? false : true,
            composed_child: data.composed_child,
            quantity: data.quantity - 1,
            quantity_init: data.quantity,
            barcode: data.barcode,
            description: data.description,
            description_translations: data.description_translations,
            kitchen_groceries: data.kitchen_groceries,
            taxrate: data.taxrate,
            rate: data.rate,
            rate_without_discount: data.rate,
            discount_amount: 0,
            total: data.rate * data.quantity, //.toFixed(2),
            total_without_discount: data.rate * data.quantity,
            thumbnail: data.thumbnail,
            tickets_elockers: data.tickets_elockers ?? [],
            variant_id: data.variant_id,
            variant_label: data.variant_label,

            elockers_active: data.elockers_active,
            elockers_type: data.elockers_type,
            elockers_locker_id: data.elockers_locker_id,
            elockers_mode: data.elockers_mode,
            tickets: [],
        });

        line = lines[len - 1];
    }

    // order lines
    let linesTipIndex = getLineById('TIP');

    lines.push(lines.splice(linesTipIndex, 1).pop());

    // store the transaction
    _state.set('transaction/setTransaction', transaction);

    await addQuantity(line, update);

    if (data.elockers_active === true && data.elockers_mode !== 'coinlock') {
        setExpireLockerTimer(true);
    }

    return true;
};

const addQuantity = async (line, update = true) => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    let tickets = false;

    // reserve lockers
    if (line.elockers_active && line.elockers_type && line.elockers_mode != 'coinlock') {
        line.loading = true;

        // set loading
        _applicationState.setLoading(true);

        tickets = await _elockers.reserve(line.elockers_type, transaction.elockers_event, transaction.elockers_location, transaction.elockers_area);

        if (tickets === false) {
            if (line.quantity === 0) {
                let lines_delete = transaction.lines.filter((ln) => {
                    return line.id == ln.id || ln.parent_id == line.id;
                });

                lines_delete.forEach((ln) => {
                    transaction.lines.splice(transaction.lines.indexOf(ln), 1);
                });

                // store the transaction
                _state.set('transaction/setTransaction', transaction);
            }
            _applicationState.setLoading(false);
            return;
        }
    }

    // set the quantity of the line
    transaction.lines
        .filter((ln) => {
            return line.id == ln.id;
        })
        .map((ln) => {
            ln.quantity = ln.quantity + 1;
            ln.total = ln.quantity * ln.rate;
            ln.total_without_discount = ln.quantity * ln.rate_without_discount;
            ln.tickets = tickets ? ln.tickets.concat(tickets) : [];
        });

    // set the quantity of the children
    transaction.lines
        .filter((ln) => {
            return ln.parent_id != null && line.id == ln.parent_id;
        })
        .map((ln) => {
            ln.quantity = ln.quantity + ln.quantity_init;
            ln.total = ln.quantity * ln.rate;
            ln.total_without_discount = ln.quantity * ln.rate_without_discount;
        });

    setTotal(transaction);

    _applicationState.setLoading(false);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);

    if (update) {
        updateFees();
    }

    return true;
};

const removeItems = async (line) => {
    let transaction = _state.get('transaction/getTransaction');

    let lines_delete = [];

    transaction.lines.filter((ln) => {
        line.filter((ln_) => {
            if (ln.item_id == ln_.item_id) {
                lines_delete.push(ln);
            }
        });
    });

    lines_delete.forEach((ln) => {
        transaction.lines.splice(transaction.lines.indexOf(ln), 1);
    });

    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);
    updateFees();
};

const setExpireLockerTimer = (setTime = false) => {
    let startTransactionTimestamp = _state.get('lockers/getStartTransactionTimestamp');

    if (setTime) {
        if (startTransactionTimestamp !== false) {
            return;
        }
        _state.set('lockers/setStartTransactionTimestamp', Date.now());
    }

    startTransactionTimestamp = _state.get('lockers/getStartTransactionTimestamp');

    if (startTransactionTimestamp === false) {
        return;
    }
    let duration = 5 * 60 * 1000 - (Date.now() - startTransactionTimestamp);
    // let duration = 15 * 1000 - (Date.now() - startTransactionTimestamp);

    if (duration <= 0) {
        clear(false);
        return;
    }

    const lockerTransactionTimeout = setTimeout(() => {
        clear(false);
        window.location.reload();
    }, duration);

    _state.set('lockers/setTransactionTimeout', lockerTransactionTimeout);
};

const cancelExpireLockerTimer = (force = false) => {
    if (force === true) {
        _state.set('lockers/clearTransactionTimeout');
        return;
    }

    let lines = _state.get('transaction/getTransaction').lines;

    let lockers = lines.filter((ln) => {
        return ln.elockers_active;
    });

    // if there are no lockers lines left in the transaction cancel the timeout
    if (lockers.length == 0) {
        _state.set('lockers/clearTransactionTimeout');
    }
};

const subtractQuantity = async (line, forceDelete = false, update = true) => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    let tickets = [];

    // reserve lockers
    if (line.elockers_active && line.elockers_type) {
        tickets = line.tickets.filter((ln) => ln.vendor == 'elockers');

        if (tickets[0]) {
            let response = await _elockers.release([tickets[tickets.length - 1]]);

            if (response === false) {
                return;
            }
            tickets.pop();
        }
    }

    // delete line
    if (line.quantity == 1 || forceDelete === true) {
        let lines_delete = transaction.lines.filter((ln) => {
            return line.id == ln.id || ln.parent_id == line.id;
        });
        lines_delete.forEach((ln) => {
            transaction.lines.splice(transaction.lines.indexOf(ln), 1);
        });
    } else {
        // set the quantity of the line
        transaction.lines
            .filter((ln) => {
                return line.id == ln.id;
            })
            .map((ln) => {
                ln.quantity = ln.quantity - 1;
                ln.total = ln.quantity * ln.rate;
                ln.total_without_discount = ln.quantity * ln.rate_without_discount;
                ln.tickets = tickets;
            });

        // set the quantity of the children
        transaction.lines
            .filter((ln) => {
                return ln.parent_id != null && line.id == ln.parent_id;
            })
            .map((ln) => {
                ln.quantity = ln.quantity - ln.quantity_init;
                ln.total = ln.quantity * ln.rate;
                ln.total_without_discount = ln.quantity * ln.rate_without_discount;
            });
    }

    setTotal(transaction);

    _state.set('transaction/setTransaction', transaction);

    if ((line.quantity == 1 || forceDelete === true) && line.elockers_active && line.elockers_type) {
        cancelExpireLockerTimer();
    }

    if (update) {
        updateFees();
    }
};

const updateDefaultItemsFee = async () => {
    let transaction = _state.get('transaction/getTransaction');

    let default_items = [];

    let location = _location.get();

    switch (transaction.method) {
        case 'takeaway':
            default_items = location.takeaway_default_items;
            break;
        case 'delivery':
            default_items = location.delivery_default_items;
            break;
        case 'table':
            default_items = location.tables_default_items;
            break;
        case 'quickorder':
            default_items = location.quickorder_default_items;
            break;
        case 'elockers':
            default_items = location.elockers_default_items;
            break;
    }

    if (!default_items || default_items.length == 0) {
        return;
    }

    let lines_delete = transaction.lines.filter((line) => {
        if (line.default_item) {
            return line;
        }
    });

    lines_delete.forEach((ln) => {
        subtractQuantity(ln, false, false);
    });

    let total = getTotalWithoutFees();

    if (total == 0) {
        return;
    }
    if (default_items) {
        default_items.forEach((default_item) => {
            // let item1 = default_item.item;
            // item.id = default_item.item.id;
            // item.default_item = true;
            // item.fixed_item = true;
            // item.type = 'revenue';
            // item.type = 'revenue';

            let item = default_item.item;

            let data = {
                parent_id: null,
                default_item: true,
                fixed_item: true,
                item_id: item.id,
                item_parent_id: null,
                type: 'revenue',
                addon: false,
                composed_child: false,
                barcode: item.attributes.barcode,
                description: item.attributes.description + (item.variant ? ' - ' + item.variant.attributes.label : ''),
                thumbnail: item.attributes.thumbnail_url,
                taxrate: item.attributes.taxrate,
                // kitchen_groceries: ,
            };

            const amount = default_item.amount > 0 ? default_item.amount : default_item.item.attributes.webshop_price;

            if (default_item.type === 'percentage') {
                data.rate = total * (amount / 100);
                data.description = `${item.description} (${amount}%)`;
            } else {
                data.rate = amount;
            }

            data.id = md5(JSON.stringify(data));
            data.quantity = item.quantity;

            addItem(data, false);
        });
    }
};

const setDeliveryfee = async () => {
    let transaction = _state.get('transaction/getTransaction');

    if (transaction.method != 'delivery' || transaction.lines.length === 0) {
        return;
    }

    let delivery_fee_data = _location.get().delivery_fee;

    if (!delivery_fee_data) {
        return;
    }

    let ln_index = getLineById('delivery_fee');

    if (ln_index !== -1) {
        subtractQuantity(transaction.lines[ln_index], false, false);
    }

    let total = getTotalWithoutFees();

    if (total == 0) {
        return;
    }

    // let delivery_fee = parseFloat(delivery_fee_data.fee);
    // let delivery_fee_max = ;
    // let line = {
    //     id: 'delivery_fee',
    //     type: 'revenue',
    //     line_is_fee: true,
    //     quantity: 1,
    //     editable: false,
    //     fixed_item: true,
    //     rate: delivery_fee,
    //     rate_without_discount: delivery_fee,
    //     total: delivery_fee,
    // };

    const item = delivery_fee_data.item;

    if (!item) {
        return;
    }

    let rate = item.attributes.price;
    if (total >= parseFloat(delivery_fee_data.freefrom)) {
        rate = 0;
    }
    let data = {
        id: 'delivery_fee',
        parent_id: null,
        default_item: true,
        fixed_item: true,
        line_is_fee: true,
        item_id: item.id,
        item_parent_id: null,
        type: 'revenue',
        addon: false,
        composed_child: false,
        barcode: item.attributes.barcode,
        description: item.attributes.description,
        thumbnail: item.attributes.thumbnail_url,
        taxrate: item.attributes.taxrate,
        quantity: 1,
        rate: rate,
        rate_without_discount: rate,
        total: rate,
    };

    addItem(data, false);
};

// payments
const addPayment = (payment) => {
    logger.log('payment added', payment)();

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // push the coupon on the transaction
    transaction.payments.push(payment);

    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);
};

const canOrder = () => {
    logger.log('can the transaction be ordered')();

    // get the transaction
    const transaction = _state.get('transaction/getTransaction');

    return transaction.lines.length > 0;
};

// coupons
const addCoupon = (coupon) => {
    logger.log('coupon added', coupon)();

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // push the coupon on the transaction
    transaction.coupons.push(coupon);

    // set the totals
    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);
};

const deleteCoupon = (coupon) => {
    logger.log('coupon deleted', coupon)();

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // delete coupon
    let coupons_delete = transaction.coupons.filter((cp) => {
        return coupon.id == cp.id;
    });

    coupons_delete.forEach((cp) => {
        transaction.coupons.splice(transaction.coupons.indexOf(cp), 1);
    });

    // set the totals
    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);
};

// elockers

const setElockersEvent = (id, name, date, next) => {
    logger.log('set elockers event', {
        id: id,
        name: name,
        date: date,
        next,
    })();

    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'elockers_event', {
        id: id,
        name: name,
        date: date,
        next,
    });
};

const setElockersLocation = (id, name) => {
    logger.log('set elockers location', name)();

    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'elockers_location', {
        id: id,
        name: name,
    });
};

const setElockersArea = (id, name) => {
    logger.log('set elockers area', name)();

    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'elockers_area', {
        id: id,
        name: name,
    });
};

const setTotal = (transaction) => {
    // todo: coupons
    // let factor = 1;
    // let couponcode = null;
    // for (const coupon of transaction.coupons) {
    //     if (coupon.type == 'discount_percentage_fixed') {
    //         factor = 1 - coupon.value;
    //         couponcode = coupon.code;
    //     }
    // }

    let number_of_items = 0;
    let total_without_discount = 0;
    let total = 0;

    // calculate the lines
    transaction.lines.forEach((ln) => {
        // ln.couponcode = couponcode;
        // ln.rate = ln.rate_without_discount * factor;
        // // ln.rate = ln.rate_without_discount;
        // ln.total_without_discount = ln.rate_without_discount * ln.quantity;
        // ln.total = round(ln.rate * ln.quantity, 2);
        // ln.discount_amount = ln.total_without_discount - ln.total;

        number_of_items = number_of_items + ln.quantity;
        total_without_discount = total_without_discount + ln.total_without_discount;
        total = total + ln.total;
    });
    // set the totals to the transaction
    transaction.number_of_items = number_of_items;
    transaction.total_without_discount = total_without_discount;
    transaction.total = total;

    // payments
    let payments = transaction.payments.filter((p) => p.status == 'paid').map((p) => p.amount);
    transaction.total_paid = payments.length ? payments.reduce((a, b) => a + b) : 0;
    transaction.total_unpaid = round(transaction.total - transaction.total_paid);

    return transaction;
};

const setOptInMarketing = (opt_in_marketing) => {
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'opt_in_marketing', opt_in_marketing);
}

// const transactionStartPayment = (payment)

const send = async () => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');
    let user = _state.get('user/getUser');

    // post the transaction to api
    let response = await _api.post('transactions', {
        account_id: user?.account_id || null,
        area_id: transaction.area ? transaction.area.id : null,
        table_id: transaction.table ? transaction.table.id : null,
        reservation_id: transaction.reservation_id || null,
        method: transaction.method,
        mode: transaction.mode,
        method_date: transaction.method_date,
        method_time: transaction.method_time,
        hooli_member_id: transaction.hooliMember?.hooliMemberId || null,
        hooli_event_id: transaction.hooliMember?.hooliEventId || null,

        customer_firstname: transaction.customer ? transaction.customer.firstname : null,
        customer_lastname: transaction.customer ? transaction.customer.lastname : null,
        customer_phone_number: transaction.customer ? transaction.customer.phone_number : null,
        customer_email: transaction.customer ? transaction.customer.email : null,
        customer_zipcode: transaction.customer ? transaction.customer.zipcode : null,
        customer_housenumber: transaction.customer ? transaction.customer.housenumber : null,
        customer_streetname: transaction.customer ? transaction.customer.streetname : null,
        customer_city: transaction.customer ? transaction.customer.city : null,
        customer_notes: transaction.customer ? transaction.customer.notes : null,

        lines: transaction.lines,
        opt_in_marketing: transaction.opt_in_marketing || false,
        // vouchers: transaction.vouchers,
    });

    // handle the response
    if (response && response.result === 'success') {
        setId(response.data.transaction_id);

        logger.log('transaction stored', response)();

        return true;
    } else {
        setId(null);

        logger.error('transaction not send')();

        return false;
    }
};

const sendToTable = async () => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    const table = transaction.table;

    // post the transaction to api
    let response = await _api.post('tabletransactions', {
        table_id: transaction.table ? transaction.table.id : null,
        lines: transaction.lines,
        // vouchers: transaction.vouchers,
    });

    // handle the response
    if (response && response.result === 'success') {
        clear();
        setTable(table);

        logger.log('transaction (table) stored', response)();

        return true;
    }

    logger.error('transaction not send')();

    return false;
};

const sendToTablePayment = async (hoursDifference = false) => {
    // get the transaction
    const transaction = _state.get('transaction/getTransaction');

    // post the transaction to api
    const response = await _api.post('transactions/table', {
        table_id: transaction.table ? transaction.table.id : null,
        hoursDifference: hoursDifference,
        // vouchers: transaction.vouchers,
    });

    // handle the response
    if (response && response.result === 'success') {
        setId(response.data.transaction_id);

        logger.log('transaction (table) stored', response)();

        return true;
    }

    logger.error('transaction not send')();

    return false;
};

const sendWithData = async (data) => {
    // post the transaction to api
    let response = await _api.post('transactions', data);

    // handle the response
    if (response && response.result === 'success') {
        setId(response.data.transaction_id);

        logger.log('transaction (table) stored', response)();

        return true;
    }

    logger.error('transaction not send')();

    return false;
};

// todo: move to global place?!
function round(value, decimals = 2) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export {
    init,
    canOrder,
    // cancel,
    cancelStoredTransaction,
    clear,
    getMethod,
    getHooliMember,
    getMethodDelivery,
    getTip,
    getTicketCounter,
    getTotalWithoutFees,
    getSufficientTotalForDelivery,
    // updateDefaultItemsFee,
    getLineAmountById,
    getTransactionFee,
    getAccount,
    setId,
    setAccount,
    setArea,
    setCustomer,
    setDiscount,
    setDeliveryAddress,
    setMethodDateTime,
    setMethod,
    setHooliMember,
    setMethodDelivery,
    setReservationId,
    setMenuDate,
    setStatus,
    setTable,
    setTip,
    setTransactionFee,
    setVouchers,
    setElockersEvent,
    setElockersLocation,
    setElockersArea,
    setExpireLockerTimer,
    addItem,
    addQuantity,
    subtractQuantity,
    removeItems,
    updateFees,
    removeTransactionFee,
    addPayment,
    // cancelPayments,
    cancelExpireLockerTimer,
    addCoupon,
    deleteCoupon,
    // print,
    send,
    sendToTable,
    sendToTablePayment,
    sendWithData,
    clearDiscount,
    applyDiscount,
    // sendByMail,

    // transactionTicketAdd,
    setOptInMarketing,
};
